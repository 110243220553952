import { render, staticRenderFns } from "./RegHrsaas.vue?vue&type=template&id=27d31775"
import script from "./RegHrsaas.vue?vue&type=script&lang=js"
export * from "./RegHrsaas.vue?vue&type=script&lang=js"
import style0 from "./RegHrsaas.vue?vue&type=style&index=0&id=27d31775&prod&lang=css"


/* normalize component */
import normalizer from "!../../node_modules/vue-loader/lib/runtime/componentNormalizer.js"
var component = normalizer(
  script,
  render,
  staticRenderFns,
  false,
  null,
  null,
  null
  
)

export default component.exports