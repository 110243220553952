<template>
    <div>
        <el-form
                v-if="regist"
                ref="registFormRef"
                :model="RegistForm"
                class="login-form"
                :rules="rules"
        >
            <div class="login-title">
                <h3>注册{{saas?'SaaS':'平台'}}账号</h3>
            </div>
            <div id="captcha-box"></div>
            <el-form-item prop="registName">
                <el-input
                        type="input"
                        placeholder="手机号码"
                        v-model.trim="RegistForm.registName"
                        autocomplete="off"
                        @change="registMobileChange" maxlength="11"
                >
                    <template slot="prepend">
                        <i class="el-icon-user"/>
                    </template>
                </el-input>
            </el-form-item>
            <el-form-item prop="registImgCode" style="margin-top: 22px">
                <el-row>
                    <el-col :span="16">
                        <el-input
                                type="input"
                                placeholder="图片验证码（区分大小写）"
                                v-model.trim="RegistForm.registImgCode"
                                autocomplete="off" maxlength="6"
                        ></el-input>
                    </el-col>
                    <el-col :span="8" style="height:47px">
                        <!-- <span style="height:47px"> -->
                        <el-image v-if="imgCode!=null"
                                  @click="changeImgCode"
                                  fit="fill"
                                  :src="imgCode"
                                  style="width: 100%;height:47px;cursor: pointer;float:right"
                        />
                        <!-- </span> -->
                    </el-col>
                </el-row>
            </el-form-item>
            <el-form-item prop="registCode" style="margin-top: 22px">
                <el-row type="flex" align="middle">
                    <el-col :span="10">
                        <el-input
                                type="input"
                                placeholder="短信验证码"
                                v-model.trim="RegistForm.registCode"
                                autocomplete="off" maxlength="6"
                        ></el-input>
                    </el-col>
                    <el-col :span="14">
                        <el-button
                                type="primary"
                                plain
                                :disabled="this.getSmsCodeStatus"
                                style="width:100%;height:47px"
                                @click="getSmsCode('registFormRef')"
                        >{{this.getSmsCodeName}}
                        </el-button>
                    </el-col>
                </el-row>
            </el-form-item>
            <el-form-item prop="registPassword" v-if="showPaswrd" style="margin-top: 22px">
                <el-input
                        type="password"
                        placeholder="设置一个登录密码"
                        v-model.trim="RegistForm.registPassword"
                        autocomplete="off" maxlength="20" show-password
                >
                    <template slot="prepend">
                        <i class="el-icon-lock"/>
                    </template>
                </el-input>
            </el-form-item>
            <el-button
                    type="primary"
                    style="width:100%;margin-bottom:10px;margin-top: 22px"
                    @click="registBtnSubmit"
                    v-loading.fullscreen.lock="fullscreenLoading"
            >同意条款并注册
            </el-button>
            <div>
                <el-button type="text" style="width:20%;float:left" @click="lookProtocolList">服务和条款</el-button>
                <el-button type="text" style="width:20%;float:right" @click="backLogin">返回登录</el-button>
            </div>
        </el-form>
        <template v-if="success">
            <div class="success-form">
                <el-row type="flex" justify="center" align="middle">
                    <div class="divclass">
                        <el-button style="width:60px;height:60px" type="success" icon="el-icon-check"
                                   circle></el-button>
                    </div>
                </el-row>
                <el-row type="flex" justify="center" align="middle">
                    <!-- <el-col> -->
                    <div class="largeTitle divclass">恭喜您，注册成功啦！</div>
                    <!-- </el-col> -->
                </el-row>
                <el-row type="flex" justify="center" align="middle">
                    <!-- <el-col> -->
                    <div class="noralTitle divclass" v-html="downTimeMessage"></div>
                    <!-- </el-col> -->
                </el-row>
                <el-row type="flex" justify="center" align="middle">
                    <div style="margin-bottom:20px">
                        <el-button type="success" @click="goToUserInfo" v-if="showPaswrd">{{buttonTxt}}</el-button>
                        <el-button type="success" @click="backLogin" v-else>前往登录</el-button>
                    </div>
                </el-row>
                <el-row type="flex" justify="center" align="middle">
                    <div class="divclass">
                        或
                        <el-button type="text" @click="backLogin">返回本站</el-button>
                    </div>
                </el-row>
            </div>
        </template>
        <template>

            <el-dialog title="" :visible.sync="procolVisible" width="60%" center :modal-append-to-body="false">
                <span v-html="agreement"></span>
            </el-dialog>

            <el-dialog title="协议清单" :visible.sync="procolListVisible" width="300px" center
                       :modal-append-to-body="false">

                <el-row v-for="item in products" :key="item.id" style="text-align:center;">
                    <el-col :span="24">
                        <el-button style="margin:5px;" @click="lookProtocol(item.code)">
                            <span>{{item.name}}平台会员服务协议</span></el-button>
                    </el-col>
                </el-row>
                <el-row style="text-align:center;">
                    <el-col :span="24">
                        <el-button style="margin:5px;" @click="lookProtocolPrivacy()"><span>隐私条款</span></el-button>
                    </el-col>
                </el-row>
            </el-dialog>

        </template>
    </div>
</template>


<script>
    import {mapActions, mapGetters} from "vuex";
    import userAPI from "@/api/userAPI.js";

    export default {
        components: {},
        props: ["saas"],

        data() {
            var pwdPowerLengthFunc = (rule, value, callback) => {
                var pwdRegex = new RegExp('^(?=.*[a-z])(?=.*[A-Z])(?=.*\\d)(?=.*[\\W_]).{8,}$');
                if (!pwdRegex.test(value)) {
                    callback(
                        new Error("请输入8位以上包含大写字母、小写字母、数字以及特殊符号组成的密码")
                    );
                } else {
                    callback();
                }
            };
            let checkMobile = (rule, value, callback) => {
                if (!value) {
                    callback(new Error("手机号不能为空"));
                }
                let re = /(^[\\\-0-9][0-9]*(.[0-9]+)?)$/; //判断字符串是否为数字//判断正整数/[1−9]+[0−9]∗]∗/
                if (!re.test(value)) {
                    callback(new Error("请输入数字值"));
                } else {
                    if (value.length != 11) {
                        callback(new Error("手机号格式不正确"));
                    } else {
                        callback();
                    }
                }
            };
            let checkSmsCode = (rule, value, callback) => {
                if (!value) {
                    callback(new Error("短信验证码不能为空"));
                }
                let re = /(^[\\\-0-9][0-9]*(.[0-9]+)?)$/; //判断字符串是否为数字//判断正整数/[1−9]+[0−9]∗]∗/
                if (!re.test(value)) {
                    callback(new Error("请输入数字值"));
                } else {
                    // if(value.length != 4){
                    //   callback(new Error('请输入4位短信验证码'));
                    // }else{
                    callback();
                    // }
                }
            };
            return {
                buttonTxt: "立即完善资料",
                agreement: "",
                ftpPath: this.__ftpPath,
                fullscreenLoading: false,
                regist: true,
                success: false,
                procolVisible: false,
                procolListVisible: false,
                showPaswrd: true,
                imgCode: null,
                // window.location.protocol +
                //   "//" +
                //   window.location.host +
                //   process.env.VUE_APP_BACKEND_URL_PROXY +
                //   "/verify?randid=" +
                //   Math.abs(Math.sin(new Date().getTime()))
                downTimeMessage:
                    "您的账户资料不完善，请完善您的账户资料。<br/> 系统将会在10秒后自动跳转至会员中心。",
                getSmsCodeName: "获取短信验证码",
                getSmsCodeStatus: false,

                RegistForm: {
                    registName: "",
                    registImgCode: "",
                    registCode: "",
                    registPassword: ""
                },
                products: [],
                rules: {
                    loginName: [
                        {
                            required: true,
                            message: "请输入用户名",
                            trigger: "change"
                        }
                    ],
                    password: [
                        {
                            required: true,
                            message: "请输入密码",
                            trigger: "change"
                        }
                    ],
                    registName: [
                        {
                            required: true,
                            message: "请输入手机号",
                            trigger: "change"
                        },
                        {validator: checkMobile, trigger: "blur"}
                    ],
                    registImgCode: [
                        {
                            required: true,
                            message: "请输入图片验证码",
                            trigger: "change"
                        }
                    ],
                    registCode: [
                        {
                            required: true,
                            message: "请输入短信验证码",
                            trigger: "change"
                        },
                        {validator: checkSmsCode, trigger: "blur"}
                    ],
                    registPassword: [
                        {
                            required: true,
                            message: "请输入密码",
                            trigger: "change"
                        },
                        {validator: pwdPowerLengthFunc}
                    ]
                },
                isSaaS: false,
            };
        },
        computed: {
            ...mapGetters({
                userinfo: "user/userinfo",
                sysConfig: "config/sysConfig"
            })
        },
        created() {
        },
        methods: {
            registMobileChange(val) {
                if (val && val.length == 11) {
                    const params = {
                        mobile: val
                    };
                    userAPI.checkMobileExist(params).then(response => {
                        if (response.status == null || response.status != 200) {
                            let message = response.data.message == null ? "" : response.data.message;
                            this.$message({
                                message: message,
                                type: "error"
                            });
                        } else {
                            let data = response.data;
                            if (data.isReged) {
                                //已注册其他产品用户
                                this.showPaswrd = false;
                                this.RegistForm.registPassword = "";
                            } else {
                                this.showPaswrd = true;
                            }
                        }
                    });
                }
            },
            lookProtocolList() {
                console.log("lookProtocolList")
                userAPI.getUserProducts().then(res => {
                    if (res.data.success) {
                        this.products = res.data.data;
                    }
                    if (this.products.length > 1) {
                        this.procolListVisible = true
                    } else if (this.products.length == 1) {
                        this.lookProtocol(this.products[0].code)
                    } else {
                        this.lookProtocol(null)
                    }
                });

            },
            //隐私协议查看
            lookProtocolPrivacy() {
                this.procolListVisible = false
                let params = {
                    type: 4,
                    code: 'privacy'
                };

                this.procolVisible = true;
                userAPI.getProductContract(params).then(res => {
                    if (res.data.success) {
                        this.agreement = res.data.data;
                    }
                });
            },

            lookProtocol(code) {
                this.procolListVisible = false
                let params = {
                    type: 3,
                    code: code

                };

                if (code != null) {
                    this.procolVisible = true;
                    if (params.code == 'zbrl') {
                        params.type = 0
                    }
                    userAPI.getProductContract(params).then(res => {
                        if (res.data.success) {
                            this.agreement = res.data.data;
                        }
                    });
                } else {
                    this.procolVisible = true;
                    userAPI.getPrcTransport(params).then(res => {
                        if (res.data.success) {
                            this.agreement = res.data.data;
                        }
                    });
                }

            },

            ...mapActions(["user/login", "user/checkLogin"]),

            backLogin() {
                //this.$router.push({ name: "login" });
                // window.location.href = "login.html#/login";


                if (this.isSaaS) {
                    window.location.href = "login.html#/login/saas";
                } else {
                    window.location.href = "login.html#/login";
                }


            },

            //获取图片验证码
            changeImgCode() {
                this.imgCode =
                    window.location.protocol +
                    "//" +
                    window.location.host +
                    process.env.VUE_APP_BACKEND_URL_PROXY +
                    "/verify?randid=" +
                    Math.abs(Math.sin(new Date().getTime()));
            },

            //获取短信验证码
            getSmsCode(ref) {
                // 样式配置
                const config = {
                    requestCaptchaDataUrl: process.env.VUE_APP_BACKEND_URL_PROXY+"/captcha/gen?type=SLIDER",
                    validCaptchaUrl: process.env.VUE_APP_BACKEND_URL_PROXY+"/captcha/check",
                    bindEl: "#captcha-box",
                    // 验证成功回调函数(必选项,必须配置)
                    validSuccess: (res, c, tac) => {
                        // 销毁验证码服务
                        tac.destroyWindow();
                        let valid = true;
                        this.$refs[ref].clearValidate();
                        this.$refs[ref].validateField(["registName", "registImgCode"], err => {
                            if (err != "") {
                                valid = false;
                            }
                        });
        
                        if (valid) {
                            let params = {
                                userImgVerifyCode: this.RegistForm.registImgCode,
                                mobile: this.RegistForm.registName,
                                type: "reg"
                            };
        
                            userAPI.getSmsVerifyCode(params).then(response => {
                                if (response.data.success) {
                                    this.waitCodeTime();
                                } else {
                                    let message =
                                        response.data.msg == null ? "获取验证码失败" : response.data.msg;
                                    this.$message({
                                        message: message,
                                        type: "error"
                                    });
                                }
                            });
                        }
                    },
                    // 验证失败的回调函数(可忽略，如果不自定义 validFail 方法时，会使用默认的)
                    validFail: (res, c, tac) => {
                        // 验证失败后重新拉取验证码
                        tac.reloadCaptcha();
                    },
                    // 刷新按钮回调事件
                    btnRefreshFun: (el, tac) => {
                        tac.reloadCaptcha();
                    },
                    // 关闭按钮回调事件
                    btnCloseFun: (el, tac) => {
                        tac.destroyWindow();
                    }
                }
                window.initTAC("./tac", config, null).then(tac => {
                    tac.init(); // 调用init则显示验证码
                }).catch(e => {
                    this.$message({message: "初始化tac失败:"+e,type: "error"});
                })
            },
            //注册按钮提交
            registBtnSubmit() {
                this.$refs.registFormRef.validate(valid => {
                    if (valid) {
                        this.fullscreenLoading = true;
                        let params = {
                            password: this.RegistForm.registPassword,
                            mobile: this.RegistForm.registName,
                            smsCode: this.RegistForm.registCode,
                            saas: this.saas != null //是否saas产品，默认是null
                        };
                        userAPI.registSubMit(params).then(response => {
                            this.fullscreenLoading = false;
                            if (response.data.success) {
                                this.success = true;
                                this.regist = false;
                                if (this.showPaswrd) {
                                    if (this.saas != null) {
                                        this.buttonTxt = "前往订阅控制台"
                                        this.downTimeMessage =
                                            "您已成功注册SaaS用户，请前往订阅产品<br/> 系统将会在10秒后自动跳转至订阅控制台。";
                                        this.$message({
                                            message: "注册成功",
                                            type: "success",
                                            onClose: () => {
                                                this.successSaaSWaitCodeTime();
                                            }
                                        });
                                    } else {
                                        this.downTimeMessage =
                                            "您的账户资料不完善，请完善您的账户资料。<br/> 系统将会在10秒后自动跳转至会员中心。";
                                        this.$message({
                                            message: "注册成功",
                                            type: "success",
                                            onClose: () => {
                                                this.successWaitCodeTime();
                                            }
                                        });
                                    }
                                } else {
                                    this.downTimeMessage =
                                        "因您的手机号已注册其他产品，请前往登录后完善资料。";
                                    this.$message({
                                        message:
                                            "注册成功，因您的手机号已注册其他产品，请前往登录后完善资料。",
                                        type: "success"
                                    });
                                }
                            } else {
                                let message =
                                    response.data.msg == null ? "注册失败" : response.data.msg;
                                this.$message({
                                    message: message,
                                    type: "error"
                                });
                            }
                        });
                    }
                });
            },

            //短信验证码倒计时
            waitCodeTime() {
                let waitSeconds = 60;
                this.getSmsCodeStatus = true;
                var interval = setInterval(() => {
                    waitSeconds--;
                    this.getSmsCodeName = "校验码已发送，请等待" + waitSeconds + "秒重试";
                    this.getSmsCodeStatus = true;
                    if (waitSeconds < 1) {
                        this.getSmsCodeName = "没收到验证码？重新获取";
                        this.getSmsCodeStatus = false;
                        clearInterval(interval);
                    }
                }, 1000);
            },
            //跳转到个人信息
            goToUserInfo() {
                this["user/login"]({
                    loginName: this.RegistForm.registName,
                    password: this.RegistForm.registPassword
                }).then(data => {
                    if (data.success) {
                        let user = data.user;
                        // if (this.saas == null) {
                            if (user.type == "货主" || user.type == "发包方")
                                window.location.href = "common.html#/shipperAuth";
                            else
                                window.location.href = "manage.html#/home";
                        // } else {
                        //     //saas用户调整
                        //     window.location.href = "common.html#/home";
                        // }
                    }
                });
            },

            //注册成功倒计时
            successSaaSWaitCodeTime() {
                let waitSeconds = 10;

                var interval = setInterval(() => {
                    waitSeconds--;
                    this.downTimeMessage =
                        "您已成功注册SaaS用户，请前往订阅产品<br/> 系统将会在" +
                        waitSeconds +
                        "秒后自动跳转至订阅控制台。";
                    if (waitSeconds < 1) {
                        clearInterval(interval);
                        window.location.href = "common.html#/home";
                    }
                }, 1000);
            },

            //注册成功倒计时
            successWaitCodeTime() {
                let waitSeconds = 10;

                var interval = setInterval(() => {
                    waitSeconds--;
                    this.downTimeMessage =
                        "您的账户资料不完善，请完善您的账户资料。<br/> 系统将会在" +
                        waitSeconds +
                        "秒后自动跳转至会员中心。";
                    if (waitSeconds < 1) {
                        clearInterval(interval);
                        this.goToUserInfo();
                    }
                }, 1000);
            }
        },

        mounted() {
            if (this.$route.params.saas != null && this.$route.params.saas == 'saas') {
                this.isSaaS = true;
            }
            userAPI.reqBeforeVerify().then(response => {
                if (response.status != null && response.status == 200) {
                    console.log('reqBeforeVerify')
                    this.changeImgCode()
                } else {
                    let message =
                        response.data.message == null ? "failed" : response.data.message;
                    this.$message({
                        message: message,
                        type: "error"
                    });
                }
            });
        }
    }
</script>
